import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Title, Input } from '../'
import { Colors } from '../../Utils/theme'

const useStyles = makeStyles((theme) =>({
  container: {
    maxWidth: 700
  },
  title: {
    fontSize: '1.875rem',
    fontWeight: 400,
    margin: 0,
    padding: '0 0 .75rem',
    flex: 1
  },
  introContainer: {
    backgroundColor: Colors.lightGrey,
    padding: '1rem',
    border: '1px solid rgba(0,0,0,.2)',
    position: 'relative',
    marginBottom: '2rem',
    borderRadius: 3
  },
  introHeader: {
    flexDirection: 'row',
    paddingBottom: '1rem'
  }
}))

function ExternalPollForm (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const validationMessage = (field) => {
    if (props.submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderIntroContent = () => {
    return (
      <>
        <Input
          label={t('title_fi')}
          value={props.titleFi}
          onChange={props.setTitleFi}
          {...validationMessage(props.titleFi)}
        />
        <Input
          label={t('title_en')}
          value={props.titleEn}
          onChange={props.setTitleEn}
          {...validationMessage(props.titleEn)}
        />
        <Input
          label={t('link_to_external_poll')}
          value={props.externalLink}
          onChange={props.setExternalLink}
          {...validationMessage(props.externalLink)}
        />
      </>
    )
  }

  return (
    <div className={classes.container}>
      <Title title={t('poll_details')} />
      <div className={classes.introContainer}>
        <div className={classes.introHeader}>
          <h3 className={classes.title}>
            {t('poll_introduction')}
          </h3>
        </div>
        {renderIntroContent()}
      </div>
    </div>
  )
}

export default ExternalPollForm
