import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import draftjsToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import { Button, Input, EditorInput } from '../'
import { Colors, Fonts } from '../../Utils/theme'
import OrderUp from '../../Assets/Icons/order-up.svg'
import OrderDown from '../../Assets/Icons/order-down.svg'
import DeleteOutlined from '../../Assets/Icons/delete-outlined.svg'
import IconLock from '../../Assets/Icons/lock.svg'
import IconLockOpen from '../../Assets/Icons/lock-open.svg'

const useStyles = makeStyles((theme) =>({
  container: {
    maxWidth: 700
  },
  title: {
    fontFamily: Fonts.heading,
    fontSize: '1.5rem',
    marginBottom: '1.5rem'
  },
  itemTitle: {
    fontFamily: Fonts.heading,
    fontSize: '1.875rem',
    marginBottom: '1.5rem'
  },
  relativeContainer: {
    position: 'relative'
  },
  itemContainer: {
    padding: '1.25rem',
    backgroundColor: Colors.lightGrey,
    marginTop: '1rem',
    marginBottom: '1rem',
    border: `1px solid rgba(0,0,0,.2)`
  },
  editorContainer: {
  },
  actionButtonsRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  flexSpacer: {
    flex: 1,
  },
  actionButton: {
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: Colors.white,
    marginLeft: '.5rem',
    cursor: 'pointer'
  },
  otherLangButton: {
    height: '2rem',
    width: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5,
    backgroundColor: Colors.white,
    marginLeft: '.5rem',
    cursor: 'pointer'
  },
  buttonIcon: {
    width: '1rem'
  },
  hidden: {
    display: 'none'
  },
  otherLangTooltip: {
    position: 'absolute',
    bottom: '2rem',
    left: '-1.5rem',
    right: '-1.5rem',
    animation: 'fadeIn .5s',
    zIndex: 99
  },
  otherLangTooltipInnerContainer: {
    overflow: 'auto',
    backgroundColor: Colors.white,
    borderRadius: 5,
    boxShadow: '0 0 2rem rgba(0,0,0,.5)',
    padding: '0 1rem 1.5rem',
    maxHeight: '80vh'
  },
  tooltipTriangleContainer: {
    marginTop: '-2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: '9rem',
  },
  tooltipTriangle: {
    width: 0,
    height: 0,
    borderLeft: '1rem solid transparent',
    borderRight: '1rem solid transparent',
    borderTop: `1rem solid ${Colors.white}`,
  },
  tooltipTitle: {
    paddingTop: '1rem',
    fontFamily: Fonts.title,
    fontSize: '1.5rem',
    marginBottom: '1rem'
  },
  tooltipContent: {
    fontSize: '1.125rem',
    paddingBottom: '2rem'
  },
  tooltipCloseButton: {
    color: Colors.red,
    fontSize: '1.125rem',
    padding: '1rem .5rem 1rem',
    float: 'right',
    top: '1rem',
    right: '1rem',
    cursor: 'pointer'
  }
}))

function DataSectionRepeater (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [locked, setLocked] = useState(false)
  const toggleLock = () => setLocked(!locked)

  const [tooltip, setTooltip] = useState(null)

  const handleAddRow = () => {
    setTooltip(null)
    const newData = props.data ? [...props.data] : []
    newData.push({
      titleFi: '',
      titleEn: '',
      contentFi: '',
      contentEn: '',
      htmlFi: '',
      htmlEn: ''
    })
    props.setData(newData)
  }

  const deleteItem = (index) => {
    setTooltip(null)
    if (window.confirm(t('confirm_delete_content_section'))) {
      props.setData(props.data.filter((item, i) => index !== i))
    }
  }

  const moveItemUp = (index) => {
    setTooltip(null)
    const newItems = [...props.data]
    if (index === 0) return
    const temp = newItems[index - 1]
    newItems[index - 1] = newItems[index]
    newItems[index] = temp
    props.setData(newItems)
  }

  const moveItemDown = (index) => {
    setTooltip(null)
    const newItems = [...props.data]
    if (index === props.data.length - 1) return
    const temp = newItems[index + 1]
    newItems[index + 1] = newItems[index]
    newItems[index] = temp
    props.setData(newItems)
  }

  const getTitle = (rowIndex) => {
    const langSuffix = props.currentLanguage === 'en' ? 'En' : 'Fi'
    return get(props.data, `[${rowIndex}]title${langSuffix}`, '')
  }

  const setTitle = (rowIndex, value) => {
    const newData = ([...props.data].map((item, index) => {
      if (index === rowIndex) {
        return {
          ...item,
          titleEn: props.currentLanguage === 'en' ? value : item.titleEn,
          titleFi: props.currentLanguage === 'fi' ? value : item.titleFi,
        }
      }
      return item
    }))
    props.setData(newData)
  }

  const getRawContent = (rowIndex) => {
    const langSuffix = props.currentLanguage === 'en' ? 'En' : 'Fi'
    return get(props.data, `[${rowIndex}]rawContent${langSuffix}`, '')
  }

  const setRawContent = (rowIndex, value) => {
    const newData = ([...props.data].map((item, index) => {
      if (index === rowIndex) {
        return {
          ...item,
          rawContentEn: props.currentLanguage === 'en' ? value : item.rawContentEn,
          rawContentFi: props.currentLanguage === 'fi' ? value : item.rawContentFi
        }
      }
      return item
    }))
    props.setData(newData)
  }

  const handleTooltipButtonPress = (rowIndex) => {
    if (tooltip) {
      // Hide tooltip
      setTooltip(null)
    } else {
      try {
        const otherLang = props.currentLanguage === 'en' ? 'fi' : 'en'
        const otherLangSuffix = capitalize(otherLang)

        const otherLangTitle = get(props.data, `[${rowIndex}].title${otherLangSuffix}`)
        const otherLangRawContent = get(props.data, `[${rowIndex}].rawContent${otherLangSuffix}`)

        const otherLangHtml = (!!otherLangRawContent && draftjsToHtml(convertToRaw(otherLangRawContent.getCurrentContent()))) || null

        setTooltip({
          title: otherLangTitle,
          content: otherLangHtml,
          rowIndex
        })
      } catch (err) {
        console.log(err)
        setTooltip(null)
      }
    }
  }

  const renderTooltipButton = (rowIndex) => {
    const otherLangText = props.currentLanguage === 'en' ? 'FI' : 'EN'
    return (
      <div
        onClick={() => handleTooltipButtonPress(rowIndex)}
        className={classes.otherLangButton}
      >
        {otherLangText}
      </div>
    )
  }

  const renderOtherLangTooltip = (rowIndex) => {
    if (tooltip && tooltip.rowIndex === rowIndex) {
      return (
        <div className={classes.relativeContainer}>
          <div className={classes.otherLangTooltip}>
            <div className={classes.otherLangTooltipInnerContainer}>
              <div
                className={classes.tooltipCloseButton}
                onClick={() => setTooltip(rowIndex)}
              >
                {t('close')}
              </div>
              <div className={classes.tooltipTitle}>{tooltip.title}</div>
              <div
                className={classes.tooltipContent}
                dangerouslySetInnerHTML={{
                  __html: tooltip.content
                }}
              />
            </div>
            <div className={classes.tooltipTriangleContainer}>
              <div className={classes.tooltipTriangle} />
            </div>
          </div>
        </div>
      )
    }
  }

  const renderArrangeButtons = (index) => {
    const totalItems = props.data.length
    return (
      <>
        {(index > 0) ? (
          <div
            onClick={() => moveItemUp(index)}
            className={classes.actionButton}
          >
            <img src={OrderUp} alt='up' className={classes.buttonIcon} />
          </div>
        ) : null}
        {(index < (totalItems - 1)) ? (
          <div
            onClick={() => moveItemDown(index)}
            className={classes.actionButton}
          >
            <img src={OrderDown} alt='down' className={classes.buttonIcon} />
          </div>
        ) : null}
      </>
    )
  }

  const renderDeleteButton = (index) => {
    return (
      <div
        onClick={() => deleteItem(index)}
        className={classes.actionButton}
      >
        <img src={DeleteOutlined} alt='delete' className={classes.buttonIcon} />
      </div>
    )
  }

  const renderLockButton = () => {
    const icon = locked ? IconLock : IconLockOpen
    return (
      <div
        onClick={toggleLock}
        className={classes.actionButton}
      >
        <img src={icon} alt='lock' className={classes.buttonIcon} />
      </div>
    )
  }

  const renderContentRow = (rowIndex) => {
    return (
      <div className={classes.itemContainer}>
        {renderOtherLangTooltip(rowIndex)}
        <div className={classes.actionButtonsRow}>
          <div className={classes.itemTitle}>{t('content_section')} {rowIndex + 1}</div>
          <div className={classes.flexSpacer} />
          {renderTooltipButton(rowIndex)}
          {renderLockButton()}
          {renderArrangeButtons(rowIndex)}
          {renderDeleteButton(rowIndex)}
        </div>
        <Input
          label={t('title')}
          value={getTitle(rowIndex)}
          onChange={(value) => setTitle(rowIndex, value)}
          disabled={locked}
          newLabel
        />
        <div className={locked ? classes.hidden : classes.editorContainer}>
          <EditorInput
            placeholder={t('placeholder_content')}
            value={getRawContent(rowIndex)}
            onChange={(value) => setRawContent(rowIndex, value)}
            label={t('content')}
            newLabel
          />
        </div>
      </div>
    )
  }

  const renderRows = () => {
    return (props.data || []).map((row, rowIndex) => {
      return (
        renderContentRow(rowIndex)
      )

    })
  }

  return (
    <>
      <div className={classes.title}>{t('content_sections')}</div>
      {renderRows()}
      <Button text={t('add_new_section')} onClick={handleAddRow}/>
    </>
  )
}

export default DataSectionRepeater
