import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { formatDatePeriod } from '../../Utils/dateTime'

const useStyles = makeStyles((theme) =>({
  container: {
    paddingTop: '2rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  card: {
    position: 'relative',
    backgroundColor: Colors.cardBackgroundColor,
    borderRadius: 5,
    padding: '1rem',
    boxSizing: 'border-box',
    height: 246,
    width: 360,
    marginBottom: '1rem',
    marginRight: '1rem',
    textDecoration: 'none',
    color: Colors.heading,
    overflow: 'hidden',
  },
  cardDeleted: {
    opacity: 0.66,
    border: `2px solid ${Colors.red}`
  },
  validity: {
    color: Colors.black70
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 400,
    margin: '0 0 1rem',
    padding: 0,
  },
  contentLimiter: {
    maxHeight: 154,
    overflow: 'hidden'
  },
  badgeRow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row'
  },
  badge: {
    backgroundColor: Colors.cardBackgroundColor,
    borderRadius: 5,
    padding: '.5rem .75rem',
    marginRight: '.5rem',
    color: Colors.content
  },
  contentText: {
    color: Colors.content,
    overflow: 'hidden',
    maxHeight: '2.2rem'
  },
  deletedBadge: {
    position: 'absolute',
    top: 0,
    right: '.5rem',
    color: Colors.white,
    background: Colors.red,
    display: 'inline-block',
    padding: '.25rem .66rem'
  }
}))

function PollGrid (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const renderBadgeRow = (item) => {
    if (item.externalLink) return (
      <div className={classes.badgeRow}>
        <div className={classes.badge}>
          {t('external_poll')}
        </div>
      </div>
    )
    return (
      <div className={classes.badgeRow}>
        <div className={classes.badge}>
          {t('question_count')} {item.questions ? item.questions.length : 0}
        </div>
        <div className={classes.badge}>
          {t('answer_count')} {item.totalAnswers || 0}
        </div>
      </div>
    )
  }

  const renderItems = () => {
    if (props.items) {
      return props.items.map((item) => (
        <Link
          key={item.id}
          to={`/polls/${item.id}`}
          className={[classes.card, (!item.active ? classes.cardDeleted : '')].join(' ')}
        >
          {!item.active && <div className={classes.deletedBadge}>{t('deleted')}</div>}
          <span className={classes.validity}>{formatDatePeriod(item.validFrom, item.validUntil)}</span>
          <div className={classes.contentLimiter}>
            <h3 className={classes.cardTitle}>{item.titleFi}</h3>
            <p className={classes.contentText}>{item.contentFi}</p>
          </div>
          {renderBadgeRow(item)}
        </Link>
      ))
    }
    return null
  }

  return (
    <div className={classes.container}>
       {renderItems()}
    </div>
  )
}

export default PollGrid
