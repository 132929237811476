import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 700,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: '1rem'
  },
  inputRoot: {
    background: Colors.white
  },
  input: {
    height: 60,
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.25rem',
    paddingBottom: 2,
    color: Colors.black,
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: '0 1rem',
    [theme.breakpoints.down('lg')]: {
      height: 50,
      fontSize: '1rem'
    }
  },
  multiline: {
    minHeight: '12rem',
    maxHeight: '12rem',
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.25rem',
    color: Colors.black,
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: '0 0.5rem',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      minHeight: '10rem',
      maxHeight: '10rem'
    }
  },
  inputLabel: {
    fontSize: '1rem',
    marginBottom: '.25rem'
  },
  label: {
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1.25rem',
    color: Colors.label,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  focusedLabel: {
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  },
  shrunkLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  }
}))

export default function Input(props) {
  const classes = useStyles()

  const onChange = (evt) => props.onChange(evt.target.value)

  return (
    <div className={classes.container}>
      {!!props.label && <div className={classes.inputLabel}>{props.label}</div>}
      <TextField
        id={props.id}
        value={props.value || ''}
        onChange={onChange}
        onKeyPress={props.handleKeyPress}
        placeholder={props.placeholder}
        InputProps={{
          classes: { root: classes.inputRoot, input: props.multiline ? classes.multiline : classes.input }
        }}
        inputProps={{ maxLength: props.maxLength }}
        type={props.type}
        multiline={props.multiline}
        variant='outlined'
        autoFocus={!!props.autoFocus}
        disabled={props.disabled}
        error={props.error}
        helperText={props.helperText}
      />
    </div>
  )
}
