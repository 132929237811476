import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import { observer } from 'mobx-react'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../../../Models/RootStore'
import { Title, PageHeader, Button,  Input, PollForm, ExternalPollForm, PageContainer, PageContent, PageActions } from '../../../Components'
import { Colors, Fonts } from '../../../Utils/theme'
import { validatePoll } from '../../../Utils/validation'
import PollModeSelect from '../../../Components/Poll/PollModeSelect'

const useStyles = makeStyles((theme) => ({
  text: {
    margin: 0,
    fontFamily: Fonts.content,
    fontSize: '1.125rem',
    fontWeight: 300,
    color: Colors.black,
    marginBottom: '1.5rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  boldText: {
    fontWeight: 700
  },
  verticalSpacer: {
    height: 30
  },
  publicationContainer: {
    padding: '2rem 1rem 1rem',
    backgroundColor: Colors.cardBackgroundColor,
    border: `1px solid ${Colors.lightBorder}`,
    borderRadius: 5,
    marginBottom: '1rem'
  }
}))

const CreatePoll = (props) => {
  const classes = useStyles()
  const { pollStore } = useStore()

  useEffect(() => {
    if (pollStore.createOk) {
      props.history.push('/polls')
    }
  }, [pollStore.createOk])

  const [titleFi, setTitleFi] = useState('')
  const [titleEn, setTitleEn] = useState('')
  const [contentFi, setContentFi] = useState('')
  const [contentEn, setContentEn] = useState('')
  const [validFrom, setValidFrom] = useState(moment().format('YYYY-MM-DD'))
  const [validUntil, setValidUntil] = useState(null)

  const [pollMode, setPollMode] = useState(null)
  const [externalLink, setExternalLink] = useState('')

  const [draft, setDraft] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  // Initialize with one empty question
  const [questions, setQuestions] = useState([{
    id: uuidv4(),
    type: 'select',
    titleFi: '',
    titleEn: '',
    contentFi: '',
    contentEn: '',
    answersFi: ['', ''],
    answersEn: ['', '']
  }])

  const handleSetValidFrom = (val) => setValidFrom(val)
  const handleSetValidUntil = (val) => setValidUntil(val)

  const { t } = useTranslation()

  const createPoll = () => {
    const pollData = {
      draft,
      titleFi,
      titleEn,
      contentFi,
      contentEn,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      questions,
      externalLink: externalLink || null
    }

    setSubmitted(true)
    pollStore.createPoll(pollData)
  }

  const renderPollModeSelect = () => {
    return (
      <PollModeSelect
        selectRegular={() => setPollMode('regular')}
        selectExternal={() => setPollMode('external')}
      />
    )
  }

  const renderExternalPollForm = () => {
    return (
      <ExternalPollForm
        titleFi={titleFi}
        setTitleFi={setTitleFi}
        titleEn={titleEn}
        setTitleEn={setTitleEn}
        externalLink={externalLink}
        setExternalLink={setExternalLink}
        submitted={submitted}
      />
    )
  }

  const renderContent = () => {
    if (!pollMode) return renderPollModeSelect()
    if (pollMode === 'external') return renderExternalPollForm()
    return (
      <PollForm
        titleFi={titleFi}
        setTitleFi={setTitleFi}
        titleEn={titleEn}
        setTitleEn={setTitleEn}
        contentFi={contentFi}
        setContentFi={setContentFi}
        contentEn={contentEn}
        setContentEn={setContentEn}
        questions={questions}
        setQuestions={setQuestions}
        submitted={submitted}
      />
    )
  }

  const validationMessage = (field) => {
    if (submitted && !field) {
      return {
        error: true,
        helperText: t('required_field')
      }
    }
    return {}
  }

  const renderDraftCheckbox = () => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={!!draft}
            onChange={() => setDraft(!draft)}
            name={t('save_as_draft')}
          />
        }
        label={t('save_as_draft')}
      />
    )
  }

  return (
    <>
      <PageHeader
        title={t('create_poll')}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
        <PageActions>
          <p className={[classes.text, classes.boldText].join(' ')}>
            {t('publication')}
          </p>
          <div className={classes.publicationContainer}>
            <Input
              type='date'
              label={t('valid_from')}
              value={validFrom}
              onChange={handleSetValidFrom}
              {...validationMessage(validFrom)}
            />
            <Input
              type='date'
              label={t('valid_until')}
              value={validUntil}
              onChange={handleSetValidUntil}
              {...validationMessage(validUntil)}
            />
          </div>
          {renderDraftCheckbox()}
          <Button
            text={t('save')}
            onClick={createPoll}
            margin
            fullWidth
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreatePoll)
