import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { Colors, Fonts } from '../../Utils/theme'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    maxWidth: 700,
    marginBottom: '1rem'
  },
  select: {
    height: 60,
    display: 'flex',
    alignItems: 'center',
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.25rem',
    color: Colors.black,
    backgroundColor: Colors.white,
    borderRadius: 5,
    padding: '0 1rem',
    [theme.breakpoints.down('lg')]: {
      height: 50,
      fontSize: '1rem'
    }
  },
  item: {
    fontFamily: Fonts.content,
    fontWeight: 300,
    fontSize: '1.25rem',
    color: Colors.black,
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem'
    }
  },
  label: {
    fontFamily: Fonts.content,
    fontWeight: 400,
    fontSize: '1.25rem',
    color: Colors.label,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem'
    }
  },
  focusedLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  },
  shrunkLabel: {
    fontWeight: 400,
    fontSize: '1rem',
    color: Colors.black
  },
  inputLabel: {
    fontSize: '1rem',
    marginBottom: '.25rem'
  }
}))

export default function BaseSelect(props) {
  const classes = useStyles()

  const onChange = (evt) => {
    props.onChange(evt.target.value)
  }

  const renderHelperText = () => {
    if (props.helperText) {
      return (
        <FormHelperText>{props.helperText}</FormHelperText>
      )
    }
    return null
  }

  return (
    <div>
      {!!props.label && <div className={classes.inputLabel}>{props.label}</div>}

      <FormControl variant='outlined' className={classes.container}>
        <Select
          labelId='select-label'
          id='select-outlined'
          value={props.value || 0}
          onChange={onChange}
          classes={{ root: classes.select }}
          disabled={props.disabled}
          error={props.error}
        >
          {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value} classes={{ root: classes.item }}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {renderHelperText()}
      </FormControl>
    </div>
  )
}
