import { makeAutoObservable } from 'mobx'
import moment from 'moment'
import { Api } from '../Services'

export default class InfluenceStore {
  rootStore
  cityFeedbacks = []
  faultReports = []
  cityFeedback = null
  faultReport = null

  loading = null

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
  }

  async getCityFeedbacks() {
    this.loading = true
    const feedbackResponse: any = await Api.getCityFeedbacks()
    const faultReportsResponse: any = await Api.getFaultReports()

    this.loading = false
    if (feedbackResponse.ok && faultReportsResponse.ok) {
      const a = feedbackResponse.data.map(item => {
        return {
          ...item,
          itemType: 'feedback'
        }
      })

      const b = faultReportsResponse.data.map(item => {
        return {
          ...item,
          itemType: 'fault_report'
        }
      })

      const feedbacks = [...a, ...b].sort((a, b) => {
        const aVal = moment(a.createdAt).valueOf()
        const bVal = moment(b.createdAt).valueOf()

        if (aVal === bVal) return 0
        if (aVal > bVal) return -1
        return 1
      })

      this.cityFeedbacks = feedbacks
    }
  }

  async getCityFeedback(id) {
    this.loading = true
    const response: any = await Api.getCityFeedback(id)
    this.loading = false
    if (response.ok) {
      this.cityFeedback = response.data
    }
  }

  async getFaultReport(id) {
    this.loading = true
    const response: any = await Api.getFaultReport(id)
    this.loading = false
    if (response.ok) {
      this.faultReport = response.data
    }
  }
}
