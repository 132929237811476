import React from 'react'
import get from 'lodash/get'
import ReactApexChart from 'react-apexcharts'

const COLORS = ['#f5bd02', '#c0c0c0', '#917042']
export default function PollRankChart (props) {

  const getRankColor = (rank) => {
    if (rank === 1) return COLORS[0]
    if (rank === 2) return COLORS[1]
    if (rank === 3) return COLORS[2]
    return '#888888'
  }

  const getRankName = (rank) => {
    if (rank === 1) return `${rank} (${rank}p)`
    return `${rank} (1/${rank}p)`
  }

  const getSeries = () => {
    return [1, 2, 3].map(rank => ({
      name: getRankName(rank),
      color: getRankColor(rank),
      data: (props.series.map(item => {
        if (item && item.rankRatios) {
          const match = item.rankRatios.find(rr => rr.rank === rank)
          if (match) return match.ratio * 100 || 0
          return 0
        }
        return 0
      }))
    }))
  }

  const getOptions = () => {
    return {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        formatter: function (val) {
          const formattedVal = parseFloat(val).toFixed(1)
          return val ? `${formattedVal} %`: val
        }
      },
      xaxis: {
        categories: get(props.options, 'labels', []),
        labels: {
          formatter: function (val) {
            return parseFloat(val || 0).toFixed(1) + ' %'
          }
        }
      },
      yaxis: {
        title: {
          text: undefined
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' %'
          }
        }
      },
      fill: {
        opacity: 1,
        colors: COLORS
      },
      legend: {
        position: 'top',
        horizontalAlign: 'left',
        offsetX: 40
      }
    }
  }

  return (
    <div>
      <ReactApexChart
        options={getOptions()}
        series={getSeries()}
        type='bar'
        height={60 * props.series.length + 20}
      />
    </div>
  )
}
