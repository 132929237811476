import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Colors } from '../../Utils/theme'
import { useTranslation } from 'react-i18next'
import { Title } from '../../Components'

const useStyles = makeStyles((theme) =>({
  container: {

  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  buttonContainer: {
    cursor: 'pointer',
    padding: '1.25rem',
    border: '2px solid rgba(0,0,0,.25)',
    borderRadius: 5,
    backgroundColor: Colors.cardBackgroundColor,
    '&:hover': {
      border: '2px solid rgba(0,0,0,.5)',
    }
  },
  subTitle: {
    fontSize: '1.5rem',
    marginBottom: '.5rem'
  },
  description: {
    fontSize: '1.125rem'
  },
  spacer: {
    width: '1rem'
  }
}))

function PollModeSelect (props) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.container}>
      <Title title={t('select_poll_mode')} />
      <div className={classes.row}>
        <div className={classes.buttonContainer} onClick={props.selectRegular}>
          <div className={classes.subTitle}>{t('regular_poll')}</div>
          <div className={classes.description}>{t('regular_poll_description')}</div>
        </div>
        <div className={classes.spacer} />
        <div className={classes.buttonContainer} onClick={props.selectExternal}>
          <div className={classes.subTitle}>{t('external_poll')}</div>
          <div className={classes.description}>{t('external_poll_description')}</div>
        </div>
      </div>
    </div>
  )
}

export default PollModeSelect
