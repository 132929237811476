import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useStore } from '../../../Models/RootStore'
import { Title, CouponForm, PageHeader, Button, PageContainer, PageContent, PageActions } from '../../../Components'

const CreateCoupon = (props) => {
  const { couponStore } = useStore()

  useEffect(() => {
    couponStore.getOrganizations()
    couponStore.getCategories()
  }, [])

  useEffect(() => {
    if (couponStore.createOk) {
      props.history.push('/coupons')
    }
  }, [couponStore.createOk])

  const [titleFi, setTitleFi] = useState(null)
  const [titleEn, setTitleEn] = useState(null)
  const [contentFi, setContentFi] = useState(null)
  const [contentEn, setContentEn] = useState(null)
  const [useTimes, setUseTimes] = useState(0)

  const [validFrom, setValidFrom] = useState(moment().format('YYYY-MM-DD'))
  const [validUntil, setValidUntil] = useState(moment().add(31, 'days').endOf('year').format('YYYY-MM-DD'))
  const [organizationId, setOrganizationId] = useState(null)
  const [categoryId, setCategoryId] = useState(null)

  const [photo, setPhoto] = useState(null)
  const [newPhoto, setNewPhoto] = useState(null)
  const [removePhoto, setRemovePhoto] = useState(false)

  const [linkFi, setLinkFi] = useState(null)
  const [linkEn, setLinkEn] = useState(null)
  const [linkLabelFi, setLinkLabelFi] = useState('Avaa linkki')
  const [linkLabelEn, setLinkLabelEn] = useState('Open link')

  const [submitted, setSubmitted] = useState(false)

  const { t } = useTranslation()

  const handleSetValidFrom = (val) => setValidFrom(val)

  const handleSetValidUntil = (val) => setValidUntil(val)

  const handleSetPhoto = (files) => {
    if (files && files.length) {
      const file = files[0]
      setPhoto(URL.createObjectURL(file))
      setNewPhoto(file)
      setRemovePhoto(false)
    }
  }

  const handleRemovePhoto = () => {
    setPhoto(null)
    setNewPhoto(null)
    setRemovePhoto(true)
  }

  const createCoupon = () => {
    setSubmitted(true)

    couponStore.createCoupon({
      titleFi,
      titleEn: titleEn || titleFi,
      contentFi,
      contentEn: contentEn || contentFi,
      useTimes,
      validFrom: validFrom ? moment(validFrom).startOf('day').toISOString() : validFrom,
      validUntil: validUntil ? moment(validUntil).endOf('day').toISOString() : validUntil,
      categoryId,
      organizationId,
      newPhoto,
      removePhoto,
      linkFi: linkFi || null,
      linkEn: linkEn || linkFi || null,
      linkLabelFi,
      linkLabelEn
    })
  }

  return (
    <>
      <PageHeader title={t('coupon_details')} />
      <PageContainer>
        <PageContent>
          <Title title={t('coupon_details')} description={t('new_coupon_details_description')} />
          <CouponForm
            validFrom={validFrom}
            setValidFrom={handleSetValidFrom}
            validUntil={validUntil}
            setValidUntil={handleSetValidUntil}
            titleEn={titleEn}
            setTitleEn={setTitleEn}
            contentEn={contentEn}
            setContentEn={setContentEn}
            titleFi={titleFi}
            setTitleFi={setTitleFi}
            contentFi={contentFi}
            setContentFi={setContentFi}
            organizations={couponStore.organizations}
            categories={couponStore.categories}
            organizationId={organizationId}
            setOrganizationId={setOrganizationId}
            categoryId={categoryId}
            setCategoryId={setCategoryId}
            useTimes={useTimes}
            setUseTimes={setUseTimes}
            photo={photo}
            setPhoto={handleSetPhoto}
            removePhoto={handleRemovePhoto}
            linkFi={linkFi}
            setLinkFi={setLinkFi}
            linkEn={linkEn}
            setLinkEn={setLinkEn}
            linkLabelFi={linkLabelFi}
            setLinkLabelFi={setLinkLabelFi}
            linkLabelEn={linkLabelEn}
            setLinkLabelEn={setLinkLabelEn}
            submitted={submitted}
            mode='create'
          />
        </PageContent>
        <PageActions>
          <Button
            text={t('create_coupon')}
            onClick={createCoupon}
            margin
            fullWidth
          />
        </PageActions>
      </PageContainer>
    </>
  )
}

export default observer(CreateCoupon)
