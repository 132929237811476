import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'
import { useStore } from '../../../Models/RootStore'
import { PageHeader, PageContainer, PageContent, FeedbackInfo } from '../../../Components'

const CityFeedback = () => {
  const { influenceStore } = useStore()
  const { cityFeedbackId }: any = useParams()

  useEffect(() => {
    influenceStore.getCityFeedback(cityFeedbackId)
  }, [])

  const { t } = useTranslation()

  const renderContent = () => {
    if (influenceStore.cityFeedback) {
      return (
        <FeedbackInfo
          item={influenceStore.cityFeedback}
        />
      )
    }
    return null
  }

  return (
    <>
      <PageHeader
        title={`${t('city_feedback')} #${cityFeedbackId}`}
      />
      <PageContainer>
        <PageContent>
          {renderContent()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default observer(CityFeedback)
