import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import orderBy from 'lodash/orderBy'
import { useStore } from '../../../Models/RootStore'
import { withRouter } from 'react-router-dom'
import { PageHeader, FeedbackTable, DataActions, PageContainer, PageContent } from '../../../Components'
import { UserSortOptions } from '../../../Constants'

function Feedbacks(props) {
  const { t } = useTranslation()
  const { influenceStore } = useStore()

  useEffect(() => {
    influenceStore.getCityFeedbacks()
  }, [])

  const [searchValue, setSearchValue] = useState('')
  const [sortValue, setSortValue] = useState(UserSortOptions[1].value)

  /* City Feedbacks */
  const getItems = () => {
    let items = influenceStore.cityFeedbacks
    if (searchValue) {
      items = items.filter((cityFeedback) => {
        const { mainCategory = '', subCategory = '', title = '', email = '' } = cityFeedback
        const targets = [mainCategory, subCategory, title, email]
        return targets.some((target) => (target || '').toLowerCase().includes(searchValue.toLowerCase()))
      })
    }

    items = items.map(item => {
      return {
        ...item,
        // Use title or main category for a-z sorting
        sortField : (item.title || '').toLowerCase() || (item.mainCategory || '').toLowerCase()
      }
    })

    if (sortValue === 'created_at') {
      items = orderBy(items, 'createdAt', 'desc')
    } else {
      items = orderBy(items, 'sortField', 'asc')
    }

    // Sort deleted last
    items = orderBy(items, 'active', 'desc')

    return items
  }

  const renderTable = () => {
    return <FeedbackTable items={getItems()} type={'city_feedback'} />
  }

  return (
    <>
      <PageHeader
        title={t('fault_reports_and_feedbacks')}
      />
      <PageContainer>
        <PageContent>
          <DataActions
            searchPlaceholder={t('search_by_name')}
            searchValue={searchValue}
            onSearchChange={setSearchValue}
            sortOptions={UserSortOptions}
            sortValue={sortValue}
            onSortChange={setSortValue}
          />
          {renderTable()}
        </PageContent>
      </PageContainer>
    </>
  )
}

export default withRouter(observer(Feedbacks))
